// CategoryMappingsEditor.tsx
import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { useTranslation } from "react-i18next";

interface LanguageMapping {
  id: string;
  name: string;
}

interface CategoryMapping {
  key: string;
  eesti: LanguageMapping;
  soome: LanguageMapping;
  saksamaa: LanguageMapping;
  EN: LanguageMapping;
}

interface Props {
  value?: CategoryMapping[];
  onChange?: (value: CategoryMapping[]) => void;
}

const CategoryMappingsEditor: React.FC<Props> = ({ value = [], onChange }) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState<CategoryMapping[]>(value);

  useEffect(() => {
    setDataSource(value);
  }, [value]);

  const handleInputChange = (
    index: number,
    lang: Exclude<keyof CategoryMapping, "key">,
    field: keyof LanguageMapping,
    newValue: string,
  ) => {
    const updated = dataSource.map((item, idx) =>
      idx === index
        ? {
            ...item,
            [lang]: {
              ...(item[lang] as LanguageMapping),
              [field]: newValue,
            },
          }
        : item,
    );
    setDataSource(updated);
    onChange?.(updated);
  };

  const columns = [
    { title: t("Key"), dataIndex: "key", key: "key", width: 150 },
    ...(["eesti", "soome", "saksamaa", "EN"] as const).flatMap((lang) => [
      {
        title: `${lang.toUpperCase()} ID`,
        dataIndex: [lang, "id"],
        key: `${lang}-id`,
        render: (text: string, _: CategoryMapping, idx: number) => (
          <Input
            value={text}
            onChange={(e) => handleInputChange(idx, lang, "id", e.target.value)}
          />
        ),
      },
      {
        title: `${lang.toUpperCase()} ${t("Name")}`,
        dataIndex: [lang, "name"],
        key: `${lang}-name`,
        render: (text: string, _: CategoryMapping, idx: number) => (
          <Input
            value={text}
            onChange={(e) =>
              handleInputChange(idx, lang, "name", e.target.value)
            }
          />
        ),
      },
    ]),
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey="key"
      pagination={false}
      bordered
      scroll={{ x: "max-content" }}
    />
  );
};

export default CategoryMappingsEditor;
