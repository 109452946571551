import React, { useEffect, useState } from "react";
import { Descriptions, Form, Input, message, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import * as apiService from "../services/apiServices";
import { displayError } from "../Helpers";
import { DeleteButton, ModifyButton } from "./styledComponents";
import { useNavigate } from "react-router-dom";
import CategoryMappingsEditor from "./CategoryMappingsEditor";

interface EditableSettingsProps {
  service: any;
  settings?: any;
  setSettings?: React.Dispatch<React.SetStateAction<any>>;
  editMode?: boolean;
}

const ServiceSettings: React.FC<EditableSettingsProps> = ({
  service,
  editMode,
}) => {
  const [settings, setSettings] = useState(service.settings || {});

  useEffect(() => {
    if (service.settings && Object.keys(service.settings).length > 0) {
      setSettings(service.settings);
    } else if (service.configuration_schema?.properties) {
      const defaultSettings: any = {};
      for (const key in service.configuration_schema.properties) {
        const property = service.configuration_schema.properties[key];
        if (!(key in defaultSettings) && property.default !== undefined) {
          defaultSettings[key] = property.default;
        }
      }
      setSettings(defaultSettings);
    }
  }, [service]);

  return editMode ? (
    <ServiceSettingsUpdate
      service={service}
      settings={settings}
      setSettings={setSettings}
    />
  ) : (
    <ServiceSettingsView service={service} settings={settings} />
  );
};

const ServiceSettingsView: React.FC<EditableSettingsProps> = ({
  service,
  settings,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEditClick = () => {
    navigate(`/services/${service.id}/edit`);
  };

  if (!service.configuration_schema?.properties || !settings) return null;

  const items = Object.entries(service.configuration_schema.properties)
    .filter(
      ([key]) =>
        settings[key] !== undefined &&
        settings[key] !== null &&
        settings[key] !== "",
    )
    .map(([key, config]: [string, any]) => {
      const rawValue = settings[key];
      const value =
        typeof rawValue === "boolean"
          ? rawValue
            ? t("Yes")
            : t("No")
          : typeof rawValue === "object"
            ? Array.isArray(rawValue)
              ? `${JSON.stringify(rawValue.slice(0, 1), null, 2)}\n...`
              : JSON.stringify(rawValue, null, 2)
            : rawValue;

      return {
        key,
        label: t(config.title),
        children: (
          <pre
            style={{
              maxWidth: "400px",
              maxHeight: "200px",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflow: "auto",
            }}
          >
            {value}
          </pre>
        ),
      };
    });

  return (
    <>
      <Descriptions
        column={1}
        items={items}
        bordered
        contentStyle={{
          padding: "16px",
          width: "100%",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      />
      <div style={{ marginTop: "16px" }}>
        <ModifyButton onClick={handleEditClick}>
          <EditOutlined /> {t("Edit")}
        </ModifyButton>
      </div>
    </>
  );
};

const ServiceSettingsUpdate: React.FC<EditableSettingsProps> = ({
  service,
  settings,
  setSettings,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(`/services/${service.id}`);
  };

  const onFinish = async (values: any) => {
    Object.entries(service.configuration_schema.properties).forEach(
      ([key, config]: [string, any]) => {
        if (
          (config.type === "object" || config.type === "array") &&
          key !== "category_mappings"
        ) {
          try {
            values[key] = JSON.parse(values[key]);
          } catch (e) {
            values[key] = {};
          }
        } else if (config.type === "integer") {
          values[key] = parseInt(values[key], 10);
        }
      },
    );

    try {
      await apiService.updateCustomerService(
        service.id,
        values,
        service.enabled,
        service.cron,
      );
      message.success(t("Settings updated"));
      setSettings?.(values);
      navigate(`/services/${service.id}`);
    } catch (e) {
      message.error(t(displayError(e, "Error updating settings")));
    }
  };

  const initialValues = { ...settings };

  // Stringify other complex fields (except category_mappings) clearly
  Object.entries(initialValues).forEach(([key, value]) => {
    if (typeof value === "object" && key !== "category_mappings") {
      initialValues[key] = JSON.stringify(value, null, 2);
    }
  });

  return (
    <Form initialValues={initialValues} layout="vertical" onFinish={onFinish}>
      {Object.entries(service.configuration_schema.properties).map(
        ([key, config]: [string, any]) => {
          if (config.type === "boolean") {
            return (
              <Form.Item
                key={key}
                label={t(config.title)}
                name={key}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            );
          }

          if (key === "category_mappings") {
            return (
              <Form.Item key={key} label={t(config.title)} name={key}>
                <CategoryMappingsEditor />
              </Form.Item>
            );
          }

          if (config.type === "array" || config.type === "object") {
            return (
              <Form.Item key={key} label={t(config.title)} name={key}>
                <Input.TextArea rows={4} />
              </Form.Item>
            );
          }

          return (
            <Form.Item key={key} label={t(config.title)} name={key}>
              <Input type={config.type === "integer" ? "number" : "text"} />
            </Form.Item>
          );
        },
      )}
      <Form.Item>
        <ModifyButton htmlType="submit">{t("Update")}</ModifyButton>
        <DeleteButton type="default" danger onClick={handleCancel}>
          <CloseOutlined /> {t("Cancel")}
        </DeleteButton>
      </Form.Item>
    </Form>
  );
};

export default ServiceSettings;
